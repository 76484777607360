import React, { useCallback, useEffect, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  Link,
  Box,
  Modal,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  styled,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import Loader from 'components/loader';
import _ from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { getAllListData, updateData, addNewData } from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { LeavePolicyAddSchema } from 'utils/ValidatorSchema';
import CustomSelect from 'components/CustomSelect/customSelect';
import CloseIcon from 'assets/png/close.svg';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';
import SearchIcon from 'assets/png/nsearch.svg';
import CloseIconNew from '@mui/icons-material/Close';
import Paginate from 'components/Pagination/Pagination';
import { useDebounce } from 'hooks';
import SelectedEmployee from './SelectedEmployee';
import { AccessDenied } from 'utils/helper';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #C7CDD8',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #C7CDD8',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme?.palette?.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme?.palette?.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});
const Defaultparam = {
  search: null,
  userId: null,
  order: null,
  skip: 0,
  sortby: null,
  status: true,
  take: 50,
  organizationId: null,
};
const AddLeavePolicy = () => {
  const { editId } = useParams();
  const navigate = useNavigate();
  const [leavePolicyopen, setLeavePolicyOpen] = useState(false);
  const [employeeModel, setEmployeeModel] = useState(false);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const handleEmployeeModelOpen = () => setEmployeeModel(true);
  const handleEmployeeModelClose = () => {
    // setSelectedUser([]);
    // getInitialStatus();
    setEmployeeModel(false);
  };
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const handleOpen = () => setLeavePolicyOpen(true);
  const handleClose = () => {
    setCreateLeavePolicy('');
    setLeavePolicyOpen(false);
  };
  const [loader, setLoader] = useState<any>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [policyLeave, setPolicyLeave] = useState<any>();
  const [createLeavePolicy, setCreateLeavePolicy] = useState<any>('');
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [removeId, setRemoveId] = useState<any>([]);

  const debouncedParams: any = useDebounce(params, 500);
  const {
    menuList,
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      policyName: '',
      noOfDays: '',
      period: '',
      employee: '',
      carryForward: '',
      earnedLeave: '',
      status: '',
      description: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(LeavePolicyAddSchema),
  });
  const overAllEmployee = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.users}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    ).then((res: any) => {
      if (res && res.data) {
        const employeeIds: any = [];
        res.data.map((item: any) => {
          employeeIds.push({ userId: item.id });
        });
        setSelectedUser([...employeeIds]);
      }
    });
  };
  const getInitialStatus = () => {
    if (editId) {
      getAllListData(`${SETTING_ENDPOINT.leavePolicy.leavePolicyList}?id=${editId}`, true)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('policyName', resp.data[0].leaveTypeId);
            setValue('noOfDays', resp.data[0].quota);
            setValue('period', resp.data[0].period);
            setValue(
              'carryForward',
              resp.data[0].carryOver === true
                ? 'true'
                : resp.data[0].carryOver === false
                  ? 'false'
                  : null,
            );
            setValue(
              'earnedLeave',
              resp.data[0].paid === true ? 'true' : resp.data[0].paid === false ? 'false' : null,
            );
            setValue('description', resp.data[0].description);
            setValue(
              'status',
              resp.data[0].status === true
                ? 'active'
                : resp.data[0].status === false
                  ? 'inActive'
                  : null,
            );
            const employeeIds: any = [];
            resp.data[0].applicableTo.map((item: any) => {
              employeeIds.push({ id: item.id, userId: item.userId });
            });
            setSelectedUser([...employeeIds]);
          }
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  };

  const onSubmitForm = (data: any) => {
    setLoader(true);
    if ((selectedUser && selectedUser.length) || isSelectedAll) {
      if (!_.isUndefined(editId)) {
        const selectAll: any = [];
        selectedUser && selectedUser?.map((re: any) => selectAll.push(re));
        updateData(
          editId,
          {
            leaveTypeId: data.policyName,
            category: 'associate',
            period: data.period,
            description: data.description,
            paid: data.earnedLeave === 'true',
            carryOver: data.carryForward === 'true',
            status: data.status === 'active',
            quota: Number(data.noOfDays),
            ...(removeId.length > 0 ? { removeUsers: removeId } : {}),
            ...(isSelectedAll
              ? { isSelectAll: true, policyUsers: [] }
              : { isSelectAll: false, policyUsers: selectAll }),
          },
          `${String(SETTING_ENDPOINT.leavePolicy.leavePolicyList)}`,
          true,
        )
          .then(() => {
            resetForm();
            navigate(`${AdminPrivateRoutes.SETTINGS.LEAVE.LEAVEPOLICY.LIST}`);
            setLoader(false);
          })
          .catch((e) => {
            if (e === 'Forbidden resource') {
              toast.error(AccessDenied);
              navigate('/dashboard');
            }
            setLoader(false);
          });
      } else {
        const selectAll: any = [];
        selectedUser && selectedUser?.map((re: any) => selectAll.push(re));
        addNewData(
          {
            leaveTypeId: data.policyName,
            category: 'associate',
            period: data.period,
            description: data.description,
            paid: data.earnedLeave === 'true',
            carryOver: data.carryForward === 'true',
            status: data.status === 'active',
            quota: Number(data.noOfDays),
            ...(removeId.length > 0 ? { removeUsers: removeId } : {}),
            ...(isSelectedAll
              ? { isSelectAll: true, policyUsers: [] }
              : { isSelectAll: false, policyUsers: selectAll }),
          },
          `${String(SETTING_ENDPOINT.leavePolicy.leavePolicyList)}`,
          undefined,
          true,
        )
          .then(() => {
            resetForm();
            navigate(`${AdminPrivateRoutes.SETTINGS.LEAVE.LEAVEPOLICY.LIST}`);
            setLoader(false);
          })
          .catch((e) => {
            if (e === 'Forbidden resource') {
              toast.error(AccessDenied);
              navigate('/dashboard');
            }
            setLoader(false);
          });
      }
    } else {
      setLoader(false);
      toast.error('Employee is required', alertOptions);
    }
  };
  const leavePolicyList = () => {
    leavePolicyList;
    getAllListData(
      `${SETTING_ENDPOINT.leavePolicy.policylist}?organizationId=${orgdy}&status=true`,
      true,
    )
      .then((res: any) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.type, value: e.id });
        });
        setPolicyLeave(options);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  };
  const LeavePolicyCreate = () => {
    setLoader(true);
    if (createLeavePolicy) {
      const value = createLeavePolicy.trim();
      addNewData(
        {
          type: value,
          status: true,
        },
        `${String(SETTING_ENDPOINT.leavePolicy.policylist)}`,
        {},
        true,
      )
        .then(() => {
          setLoader(false);
          setCreateLeavePolicy('');
          leavePolicyList();
          handleClose();
        })
        .catch(() => {
          setLoader(false);
        });
    } else {
      setLoader(false);
      toast.error('Leave policy is required', alertOptions);
    }
  };
  const getList = () => {
    setLoader(true);
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    getAllListData(
      `${SETTING_ENDPOINT.users}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res: any) => {
        setpagetotal(Number(res.total));
        setEmployeeList(res && res.data);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };
  const selectOne = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
      if (e.target.checked) {
        setSelectedUser((pre: any) => {
          pre.push({ userId: data.id });
          return [...pre];
        });
      } else {
        setSelectedUser((pre: any) => {
          return [...pre.filter((e: any) => e.userId !== data.id)];
        });
        setRemoveId((pre: any) => {
          const final: any = selectedUser.find((e: any) => e.userId === data.id);
          pre.push(final?.id);
          return [...pre];
        });
      }
    },
    [employeeList],
  );
  const selectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsSelectedAll(e.target.checked);
      if (e.target.checked) {
        overAllEmployee();
      } else {
        setSelectedUser([]);
      }
    },
    [setEmployeeList, employeeList],
  );

  useEffect(() => {
    leavePolicyList();
    getInitialStatus();
  }, []);

  useEffect(() => {
    getList();
  }, [debouncedParams]);

  useEffect(() => {
    if (!_.isEmpty(menuList)) {
      const settingData = menuList
        .find((e: any) => e.moduleName === 'Settings')
        .screens.find((a: any) => a.screenName === 'Leave Policy');
      if (!settingData) {
        navigate('/');
      }
    }
  }, [menuList]);

  return (
    <>
      <Grid container>
        {loader && <Loader style={{ zIndex: 1301 }} />}
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link
                underline='hover'
                color='inherit'
                href={AdminPrivateRoutes.SETTINGS.LEAVE.LEAVEPOLICY.LIST}>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Leave Policy
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                {editId ? 'Edit' : 'Create'} Leave Policy
              </Typography>
            </Breadcrumbs>
          </Typography>
          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>
              {' '}
              {editId ? 'Edit' : 'Create'} Leave Policy
            </Typography>
            <Typography component={'div'} className='d-flex-a'>
              <Button
                className='cancel-btn mr-16'
                onClick={() => navigate('/settings/leave/leave-policy')}>
                Cancel
              </Button>
              <Typography
                component={'div'}
                className='add-btn-apruv mr-1'
                onClick={() => {
                  handleEmployeeModelOpen();
                }}>
                <Typography component={'div'}>+ Add Employee</Typography>{' '}
              </Typography>
              <Button form={'branch-add'} className='s-add-btn' type='submit'>
                Save
              </Button>
            </Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <form
                className='private-form'
                id={'branch-add'}
                onSubmit={handleSubmit(onSubmitForm)}>
                <Grid container>
                  <Grid item md={8} sm={12} xs={12}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Leave Policy Type<span>*</span>
                          </Typography>
                          <Controller
                            name='policyName'
                            control={control}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  disabled={editId ? true : false}
                                  options={
                                    policyLeave?.length
                                      ? [
                                          ...policyLeave,
                                          {
                                            label: '+ Add New Leave Policy',
                                            value: 'addLeavePolicy',
                                            isDisabled: true,
                                            isFixed: true,
                                            isSelectable: false,
                                            custmdwn: true,
                                          },
                                        ]
                                      : [
                                          {
                                            label: '+ Add New Leave Policy',
                                            value: 'addLeavePolicy',
                                            isDisabled: true,
                                            isFixed: true,
                                            isSelectable: false,
                                            custmdwn: true,
                                          },
                                        ]
                                  }
                                  name={name}
                                  value={value}
                                  custmfn={handleOpen}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Leave Policy Type'
                                  error={errors.policyName?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Number of Days<span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='noOfDays'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  className='custom-input'
                                  placeHolder='Number of Days'
                                  error={errors.noOfDays?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Leave Period<span>*</span>
                          </Typography>
                          <Controller
                            name='period'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'Monthly', value: 'monthly' },
                                  {
                                    label: 'Annually',
                                    value: 'annually',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Leave Period'
                                error={errors.period?.message}
                              />
                            )}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Carry Forward<span>*</span>
                          </Typography>
                          <Controller
                            name='carryForward'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'Yes', value: 'true' },
                                  {
                                    label: 'No',
                                    value: 'false',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Carry Forward'
                                error={errors.carryForward?.message}
                              />
                            )}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Earned Leave<span>*</span>
                          </Typography>
                          <Controller
                            name='earnedLeave'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'Yes', value: 'true' },
                                  {
                                    label: 'No',
                                    value: 'false',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Earned Leave'
                                error={errors.earnedLeave?.message}
                              />
                            )}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Policy Status<span>*</span>
                          </Typography>
                          <Controller
                            name='status'
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'Active', value: 'active' },
                                  {
                                    label: 'Inactive',
                                    value: 'inActive',
                                  },
                                ]}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Policy Status'
                                error={errors.status?.message}
                              />
                            )}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>Description</Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='description'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  isTextArea={true}
                                  className='custom-input'
                                  placeHolder='Type your Description..'
                                  error={errors.description?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>

                      <Grid item md={12} sm={12} xs={12}>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                    </Grid>
                    {editId && <SelectedEmployee setLoader={setLoader} id={editId} />}
                  </Grid>
                </Grid>
              </form>
            </Typography>
          </Typography>
        </Grid>
        <Modal open={employeeModel} onClose={() => false}>
          <Box className='email-modal' sx={{ width: '865px !important', minHeight: '45%' }}>
            <Typography component={'div'} className='d-flex-a flex-sb'>
              <Typography className='l-title' sx={{ display: 'flex' }}>
                {'Add Employees'}
              </Typography>
              <Typography className='text-right' sx={{ display: 'flex' }}>
                <TextField
                  className='custom-search mr-1'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '200px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 10,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIconNew style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography component={'div'} className='d-flex-a'>
                  <Button
                    className='cancel-btn mr-16'
                    onClick={() => {
                      setSelectedUser([]);
                      getInitialStatus();
                      setEmployeeModel(false);
                    }}>
                    Cancel
                  </Button>
                  <Button className='s-add-btn' onClick={handleEmployeeModelClose}>
                    Add
                  </Button>
                </Typography>
              </Typography>
            </Typography>
            <Grid container className='private-form'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='mt-32'>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      className='custom-table'
                      aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              sx={{ padding: 'unset' }}
                              checkedIcon={<CheckedIcon />}
                              icon={<CheckIcon />}
                              name='checkbox'
                              checked={pagetotal === selectedUser.length}
                              onChange={selectAll}
                            />
                          </TableCell>
                          <TableCell>Emp. Id</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Role </TableCell>
                          <TableCell>Branch</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeeList && employeeList.length ? (
                          employeeList.map((row: any) => {
                            const checkd: boolean = selectedUser.some(
                              (e: any) => e.userId === row.id,
                            );
                            return (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{ textTransform: 'capitalize' }}>
                                  <Checkbox
                                    sx={{ padding: 'unset' }}
                                    checkedIcon={<CheckedIcon />}
                                    icon={<CheckIcon />}
                                    name='checkbox'
                                    checked={Boolean(checkd)}
                                    onChange={(e) => selectOne(e, row)}
                                  />
                                </TableCell>
                                <TableCell sx={{ textTransform: 'capitalize' }}>
                                  {row && row.empCode ? row.empCode : '-'}
                                </TableCell>
                                <TableCell>
                                  {row && row.firstName ? `${row.firstName} ${row.lastName}` : '-'}
                                </TableCell>
                                <TableCell>{row && row.roleName ? row.roleName : '-'}</TableCell>
                                <TableCell sx={{ textTransform: 'capitalize' }}>
                                  {row && row.branchName ? row.branchName : '-'}
                                </TableCell>
                              </TableRow>
                            );
                          })
                        ) : (
                          <tr className={'text-center'}>
                            <td colSpan={5} align={'center'}>
                              No Record(s) Found.
                            </td>
                          </tr>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Typography>
                <Grid container className='mt-10'>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography component={'div'} className='d-flex-a flex-end pagination'>
                      <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={leavePolicyopen}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box className='logout-modal'>
            <Typography className='text-right'>
              <img src={CloseIcon} alt='close' onClick={handleClose} />
            </Typography>
            <Typography className='l-title'>Add New Leave Policy!</Typography>
            <Grid container className='private-form'>
              <Grid item md={12} sm={12} xs={12} sx={{ padding: '20px 100px 16px 100px' }}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'} sx={{ marginRight: '10px' }}>
                    New Leave Policy <span>*</span>
                  </Typography>
                  <CustomInput
                    className='custom-input'
                    placeHolder='Enter the New Leave Policy'
                    onChange={(e) => {
                      setCreateLeavePolicy(e.target.value);
                    }}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Typography className='d-flex-ja mb-24'>
              <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                Cancel
              </Button>
              <Button className='l-save-btn' onClick={LeavePolicyCreate}>
                save
              </Button>
            </Typography>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};
export default AddLeavePolicy;
