export const MASTER_ENDPOINT = {
  Login: 'auth/login',
  Logout: 'auth/logout',
  verifyOtp: 'auth/verify-otp',
  generateOtp: 'auth/generate-otp',
};
export const ClientsENDPOINT = {
  Client: 'client',
  CilentPercentage: 'client/percentage',
  ClientLevel: 'client/level',
  ClientCTC: 'client/ctc',
  ClientDays: 'client/days',
  ClientProposalVersion: 'client/proposal-version',
};

export const PositionENDPOINT = {
  position: 'position',
  CRMChange: 'position/crm',
};
export const PlanENDPOINT = {
  plan: 'plan',
};
export const BillingENDPOINT = {
  billing: 'billing',
  PaymentMail: 'billing/payment-mail',
  billingPayment: 'billing/payment',
  billingInvoice: 'billing/invoice',
  cancelInvoice: 'billing/invoice/cancel',
  clientAgreement: 'billing/client-agreement',
};
export const CVBANKENDPOINT = {
  cvBank: 'cv-bank',
};
export const ResumesENDPOINT = {
  resumeListData: 'resumes/list-screen',
  resumeSkills: 'resumes/skills',
  resumeList: 'resumes',
  resumeUpload: 'resumes/upload',
  resumeSnapshot: 'resumes/snapshot',
  download: 'resumes/download',
  cvUpload: 'email-template/send-cv',
  sendCV: 'resumes/send-cv',
  emailTempalate: 'email-template/interview',
  interview: 'interview',
  OverAllStatus: 'resumes/overall-status',
  task: 'resumes/task',
  resumeDuplicate: 'resumes/duplicate',
};

export const InterviewENDPOINT = {
  interviewList: 'interview',
};
export const MailboxENDPOINT = {
  mailboxList: 'mail-box',
};
export const VERSIONHISTORYENDPOINT = {
  versionHistory: 'version-history',
};

export const SETTING_ENDPOINT = {
  Branch: 'branch',
  Qualification: 'qualification',
  Qualificationdetails: 'qualification/list',
  Degreedetails: 'qualification/degree',
  EmailTemplate: 'email-template',
  functionalArea: 'functional-area',
  designation: 'designation',
  users: 'users',
  roles: 'roles',
  dailylogindetails: 'activity',
  userFunctionalRoles: 'roles/user-functional-roles',
  moduleScreenList: 'roles/modules',
  rolesPermission: 'roles/permission',
  leaves: {
    holidays: 'holiday',
    importLeave: 'import-leave',
    leavePolicy: 'leave-policy',
  },
  leavePolicy: {
    policylist: 'leave-policy/type',
    leavePolicyList: 'leave-policy',
  },
  Industry: 'industry',
  OverAllLeaves: 'holiday/leave-days',
};
export const EVENTS_ENDPOINT = {
  event: 'event',
};
export const PROFILE_ENDPOINT = {
  profile: 'users',
};

export const FILE_ENDPOINT = {
  files: 'files',
};

export const LEAVE_ENDPOINT = {
  policyTypeGet: 'leave-policy/type',
  leaves: 'leaves',
};
