/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
  Radio,
  RadioGroup,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EmailIcon from 'assets/png/email.svg';
import { To, useNavigate } from 'react-router-dom';
import BanImg from 'assets/png/pro-banner.png';
import { styled } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { getAllListData, getS3ApiFile, getSingleData, updateData } from 'services/CommonServices';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { profileEditFormSchema } from 'utils/ValidatorSchema';
import CustomInput from 'components/CustomInput/customInput';
import _, { isEmpty } from 'lodash';
import { FILE_ENDPOINT, PROFILE_ENDPOINT } from 'utils/Constant';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import { Country, State, City } from 'country-state-city';

// import PhoneInput from 'react-phone-input-2';

const Myprofile = () => {
  const { userInfo: userTokenData } = useAppSelector((store) => store.userLogin);
  const [imageUrl, setImageUrl] = useState<any>('');
  const [uploadImage, setUploadImage] = useState<any>({});
  const [localImage, setLocalImage] = useState<any>('');
  const [userInfo, setUserInfo] = useState<any>({});
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [removeImage, setRemoveImage] = useState<string>('');
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const {
    control: control,
    handleSubmit: handleSubmit,
    setValue,
    // trigger,
    reset: resetForm,
    trigger,
    // setError,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      title: '',
      firstName: '',
      lastName: '',
      address: '',
      state: '',
      city: '',
      saturdayleave: 'even',
      isSunday: true,
      pincode: '',
      country: '',
      email: '',
      phone: '',
      status: '',
      formattedValuePhone: '',
      countryCodeText: '',
      branchId: '',
      empCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(profileEditFormSchema),
  });
  const getData = () => {
    getAllListData(`${PROFILE_ENDPOINT.profile}?id=${userTokenData.id}`, true)
      .then((res) => {
        if (res && !_.isEmpty(res.data)) {
          const imageId = res?.data[0]?.file ? res?.data[0]?.file?.id : '';
          setUserInfo(res.data[0]);
          setValue('title', res.data[0].title);
          setValue('empCode', res.data[0].empCode);
          setValue('address', res.data[0].address);
          setValue('empCode', res.data[0].empCode);
          setValue('firstName', res.data[0].firstName);
          setValue('lastName', res.data[0].lastName);
          setValue('state', res.data[0].state);
          setValue('city', res.data[0].city);
          setValue('country', res.data[0].country);
          setValue('pincode', res.data[0].pincode);
          setValue('email', res.data[0].email);
          setValue('isSunday', res.data[0]?.isSundayLeave);
          setValue('saturdayleave', res.data[0]?.saturdayLeave);
          setValue('phone', `${res.data[0].countryCode}${res.data[0].phoneNumber}`);
          setValue('formattedValuePhone', `${res.data[0].countryCode}${res.data[0].phoneNumber}`);
          setValue('countryCode', `${res.data[0].countryCode}`);
          setValue('status', res.data[0].status);
          setValue('branchId', res.data[0].branchId ? res.data[0].branchId : '');
          if (!_.isEmpty(imageId)) {
            getSingleData('', `${FILE_ENDPOINT.files}/${imageId}`, true, {
              responseType: 'blob',
            }).then((fileRes: any) => {
              setUploadImage(URL.createObjectURL(fileRes));
              setImageUrl(URL.createObjectURL(fileRes));
              setLoader(false);
            });
          }
          setLoader(false);

          // axios
          //   .get(`${process.env.REACT_APP_APIURL}${FILE_ENDPOINT.files}/${userInfo.file.id}`)
          //   .then((fileRes: any) => {
          //     console.log(fileRes);
          // console.log(fileRes);
          // const blobFile = new Blob([fileRes], { type: 'text/csv' });
          // const url = window.URL.createObjectURL(blobFile);
          // const file = new File([fileRes], 'abc.jpeg');
          // setImageUrl(URL.createObjectURL(file));
          // setImageUrl(url);
          // });
        }
      })
      .catch((err) => console.warn(err));
  };
  const onSubmitForm = (data: any) => {
    // if (editProfile) {
    if (_.isEmpty(uploadImage)) {
      toast.error(String('Profile Image is required'), {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
      return;
    }
    setLoader(true);
    const formData = new FormData();
    formData.append('firstName', data.firstName);
    formData.append('empCode', data.empCode);
    formData.append('lastName', data.lastName);
    formData.append('title', data.title.toLowerCase());
    formData.append('address', data.address);
    formData.append('state', data.state);
    formData.append('city', data.city);
    formData.append('pincode', data.pincode);
    formData.append('country', data.country);
    formData.append('email', data.email);
    formData.append('isSundayLeave', data.isSundayLeave);
    formData.append('saturdayLeave', data.saturdayleave);
    formData.append('phoneNumber', String(data.phone).slice(data.countryCode.length));
    formData.append('roleId', userTokenData?.roleId);
    formData.append(
      'countryCode',
      `+${data?.countryCode
        .split('')
        .filter((ep: string) => ep != '+')
        .join('')}`,
    );
    formData.append('organizationId', userTokenData?.organizationId);
    formData.append('status', data.status);
    if (userTokenData?.roleId !== 15) {
      formData.append('designation', userInfo?.designation);
      userInfo?.reportingToL1 ? formData.append('reportingToL1', userInfo?.reportingToL1) : '';
      userInfo?.reportingToL2 ? formData.append('reportingToL2', userInfo?.reportingToL2) : '';
      formData.append('crm', userInfo?.crm);
    }
    userInfo?.imageId ? formData.append('imageId', userInfo?.imageId) : '';
    uploadImage[0] ? formData.append('profile', uploadImage[0]) : '';
    // removeImage ? formData.append('removeImageId', removeImage) : '';
    formData.append('branchId', data.branchId ? data.branchId : '');
    updateData(userTokenData.id, formData, PROFILE_ENDPOINT.profile, true, {
      'Content-Type': 'multipart/form-data',
    })
      .then((res) => {
        if (res && !_.isEmpty(res)) {
          setLocalImage('');
          setUploadImage({});
          // setRemoveImage('');
          setEditProfile(false);
          getData();
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err.message);
      });
    // }
  };

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck.length - 1].toLowerCase(),
    );
    if (formatCheck) {
      setUploadImage(e?.target?.files);
      setLocalImage(URL.createObjectURL(e?.target?.files[0]));
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };
  useEffect(() => {
    if (watch('country')) {
      const options: any = [];
      State.getStatesOfCountry(watch('country')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStates(options);
    }
  }, [watch('country')]);

  useEffect(() => {
    if (watch('state')) {
      const options: any = [];
      City.getCitiesOfState(watch('country'), watch('state')).map((city: any) => {
        options.push({ label: city.name, value: city.name });
      });
      setCities(options);
    }
  }, [watch('country'), watch('state')]);

  useEffect(() => {
    if (!_.isEmpty(userTokenData)) {
      getData();
    }
  }, [userTokenData]);

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              My Profile
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>My Profile</Typography>
          <Typography component={'div'} className='d-flex-a'>
            {editProfile ? (
              <>
                <Button
                  className='cancel-btn mr-16'
                  onClick={() => {
                    resetForm();
                    setLocalImage('');
                    // setRemoveImage('');
                    getData();
                    setEditProfile(false);
                  }}>
                  Cancel
                </Button>
                <Button form={'profile-edit'} className='s-add-btn' type='submit'>
                  Save
                </Button>
              </>
            ) : (
              <Button
                form={'profile-edit'}
                className='s-add-btn'
                type='button'
                onClick={() => setEditProfile(true)}>
                Edit Profile
              </Button>
            )}
          </Typography>
        </Typography>

        <form className='private-form' id={'profile-edit'} onSubmit={handleSubmit(onSubmitForm)}>
          <Typography component={'div'} className='pad-box'>
            <Grid container columnSpacing={3.25}>
              <>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='pro-box'>
                    {localImage ? (
                      <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                        <img className='default-pic' src={localImage} />
                      </Typography>
                    ) : userInfo?.file?.id ? (
                      <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                        <img className='default-pic' src={imageUrl} />
                      </Typography>
                    ) : (
                      <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                        <Typography className='default-pic'>
                          {userTokenData?.firstName.toUpperCase().trim().split('')[0] +
                            userTokenData?.lastName.toUpperCase().trim().split('')[0]}
                        </Typography>
                      </Typography>
                    )}

                    <Typography
                      sx={{ textTransform: 'capitalize' }}
                      className='pro-name'>{`${userInfo?.firstName} ${userInfo?.lastName}`}</Typography>
                    <Typography className='pro-role'>
                      {userInfo?.roleId === 15 ? 'Administrator' : userInfo?.roleName}
                    </Typography>
                    <Typography className='d-flex-ja mt-35'>
                      <Button
                        component='label'
                        // onClick={() => fileRef.current.click()}
                        disabled={!editProfile ? true : false}
                        sx={{ opacity: !editProfile ? '0.5' : '' }}
                        className='theme-btn mr-16'>
                        Upload Photo
                        {editProfile && (
                          <input
                            hidden
                            type='file'
                            accept='image/*'
                            onChange={(e) => updatePhoto(e)}
                            onClick={(event: any) => {
                              event.target.value = null;
                            }}
                          />
                        )}
                      </Button>

                      {/* <Button
                        onClick={() => {
                          setRemoveImage(userInfo?.file?.id ? userInfo?.file?.id : '');
                          setLocalImage('');
                          setUploadImage({});
                        }}
                        className='theme-btn'
                        sx={{ opacity: !editProfile || _.isEmpty(uploadImage) ? '0.5' : '' }}
                        disabled={!editProfile || _.isEmpty(uploadImage) ? true : false}>
                        Remove Photo
                      </Button> */}
                    </Typography>
                    <Typography component={'div'} className='mt-147'>
                      <Typography className='join-text mb-16'>
                        Joined on <span>{moment(userInfo?.createdAt).format('DD MMMM YYYY')}</span>
                      </Typography>
                      <Typography className='status-text text-center'>
                        User status
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='status'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <Switch
                                value={value}
                                name={name}
                                sx={{ opacity: !editProfile ? '0.5' : '' }}
                                disabled={!editProfile ? true : false}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                className='custom-switch'
                                checked={value}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                  <Typography component={'div'} className='form-box'>
                    <Typography className='form-title'>Personal Information</Typography>

                    <Grid container columnSpacing={4.25}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Grid container columnSpacing={4.25}>
                          <Grid item md={6} sm={12} xs={12}>
                            <Typography component={'div'} className='custom-field'>
                              <Typography component={'p'}>
                                Title <span>*</span>
                              </Typography>
                              <Controller
                                control={control}
                                defaultValue={null}
                                name='title'
                                render={({ field: { onChange, value, name } }) => {
                                  return (
                                    <CustomInput
                                      value={value}
                                      name={name}
                                      disabled={!editProfile ? true : false}
                                      className='custom-input'
                                      placeHolder='Enter Title'
                                      error={errors.title?.message}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                  );
                                }}
                              />
                              {/* <TextField
                          className='custom-input'
                          id='standard-basic'
                          placeholder=''
                          variant='standard'
                        /> */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            First Name <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='firstName'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter First Name'
                                  error={errors.firstName?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Last Name <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='lastName'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter Last Name'
                                  error={errors.lastName?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography className='form-title mt-8'>Contact Information</Typography>

                    <Grid container columnSpacing={4.25}>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Address <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='address'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter Address'
                                  error={errors.address?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Country <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='country'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={country}
                                  value={value}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter Country'
                                  error={errors.country?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                    setValue('state', '');
                                    setValue('city', '');
                                  }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>
                      {/* <Grid item md={6} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Address2 <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={null}
                      name='address2'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Address2'
                            error={errors.address2?.message}
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                 
                  </Typography>
                </Grid> */}
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            State <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='state'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={states}
                                  value={value ? value : null}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter State'
                                  error={errors.state?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                    setValue('city', '');
                                  }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            City <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='city'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={cities}
                                  value={value ? value : null}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter City'
                                  error={errors.city?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Pin Code <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='pincode'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  type='number'
                                  minLength={6}
                                  maxLength={7}
                                  disabled={!editProfile ? true : false}
                                  className='custom-input'
                                  placeHolder='Enter Pincode'
                                  error={errors.pincode?.message}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>

                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Email <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='email'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  disabled={!editProfile ? true : false}
                                  inputProps={{
                                    startAdornment: (
                                      <InputAdornment position='start'>
                                        <img src={EmailIcon} alt='email' />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  error={errors.email?.message}
                                  className='custom-input'
                                  placeHolder='Enter Email'
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Phone No <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='formattedValuePhone'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                // <CustomInput
                                //   value={value}
                                //   type='number'
                                //   minLength={10}
                                //   maxLength={11}
                                //   name={name}
                                //   disabled={!editProfile ? true : false}
                                //   className='custom-input'
                                //   placeHolder='Enter Phone Number'
                                //   error={errors.phone?.message}
                                //   onChange={(e) => {
                                //     onChange(e);
                                //   }}
                                // />
                                // <PhoneInput
                                //   containerClass='custom-field'
                                //   country={'eg'}
                                //   enableSearch={true}
                                //   value={value}
                                //   onChange={(e) => onChange(e)}
                                // />
                                <CustomPhoneInput
                                  disabled={!editProfile ? true : false}
                                  placeholder='(000) 000-0000'
                                  containerClass={
                                    errors.phoneNumber?.message ? 'phone-input-error' : ''
                                  }
                                  inputClass={
                                    errors.phoneNumber?.message
                                      ? 'phone-input phone-input-no-border'
                                      : 'phone-input'
                                  }
                                  initialCountry={'us'}
                                  specialLabel={''}
                                  searchClass={'search-custom-class'}
                                  value={value}
                                  enableSearch={true}
                                  searchNotFound={'No Country Found'}
                                  inputStyle={{
                                    width: '100%',
                                    padding: '9px 11px',
                                    paddingLeft: '60px',
                                    borderRadius: '6px',
                                    color:
                                      value && String(value).length > 0
                                        ? !editProfile
                                          ? '#bdbdbd'
                                          : '#344051'
                                        : '#bdbdbd',
                                    fontSize: '16px',
                                    fontFamily: 'Poppins-Regular',
                                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                  }}
                                  onChange={(value, data, event, formattedValue) => {
                                    const { countryCode, dialCode } = data;
                                    console.log(formattedValue);

                                    // setValue(
                                    //   'homePhoneISO2',
                                    //   countryCode.toUpperCase(),
                                    // );
                                    // console.log(countryCode,'countryCode',value,data);
                                    // onChange(formattedValue);
                                    setValue('countryCode', dialCode);
                                    setValue('countryCodeText', countryCode);
                                    setValue('phone', value);
                                    setValue('formattedValuePhone', formattedValue);
                                    trigger('formattedValuePhone');
                                  }}
                                  error={
                                    errors.formattedValuePhone?.message
                                      ? errors.formattedValuePhone?.message
                                      : ''
                                  }
                                  inputProps={{ tabIndex: 11 }}
                                />
                              );
                            }}
                          />
                          {/* <TextField
                      className='custom-input'
                      id='standard-basic'
                      placeholder=''
                      variant='standard'
                    /> */}
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Saturday Leave <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={null}
                            name='saturdayleave'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  disabled
                                  options={[
                                    { label: 'ALL', value: 'all' },
                                    { label: 'ODD', value: 'odd' },
                                    { label: 'EVEN', value: 'even' },
                                    { label: 'NONE', value: 'none' },
                                  ]}
                                  name={name}
                                  value={value}
                                  onChange={onChange}
                                  placeHolder='Select'
                                  error={errors.saturdayleave?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'} style={{ marginBottom: '10px' }}>
                            Sunday Leave <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            name='isSunday'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <RadioGroup
                                  className='custom-radio'
                                  row
                                  aria-labelledby=''
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}>
                                  <FormControlLabel
                                    value={true}
                                    disabled
                                    control={<Radio />}
                                    label='Yes'
                                  />
                                  <FormControlLabel
                                    value={false}
                                    disabled
                                    control={<Radio />}
                                    label='No'
                                  />
                                </RadioGroup>
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
              </>
            </Grid>
          </Typography>
        </form>
      </Grid>
    </Grid>
  );
};

export default Myprofile;
