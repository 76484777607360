/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Breadcrumbs,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Link,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import EmailIcon from 'assets/png/email.svg';
import { To, useNavigate, useParams } from 'react-router-dom';
import BanImg from 'assets/png/pro-banner.png';
import { styled } from '@mui/material/styles';
import CustomSelect from 'components/CustomSelect/customSelect';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Avatar from 'assets/png/avatarIcon.svg';
import react, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomInput from 'components/CustomInput/customInput';
import { useForm, Controller } from 'react-hook-form';
import { userFormSchema } from 'utils/ValidatorSchema';
import _ from 'lodash';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { updateData, addNewData, getAllListData, getSingleData } from 'services/CommonServices';
import { FILE_ENDPOINT, MASTER_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/loader';
import moment from 'moment';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import { defaultText } from 'utils/Config';
import { toast } from 'react-toastify';
import Editor from 'ck-editor-plugin/build/ckeditor';
import { AccessDenied } from 'utils/helper';
import { Country, State, City } from 'country-state-city';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const UsersAdd = () => {
  const [uploadImage, setUploadImage] = useState<any>({});
  const [localImage, setLocalImage] = useState<any>('');
  const [userData, setUserData] = useState<any>({});
  // const [removeImage, setRemoveImage] = useState<string>('');
  const { editId } = useParams();
  const [editorContent, setEditorContent] = useState<any>(defaultText);
  const [rollist, setrollist] = useState<any>([]);
  // const [l1Reporting, setL1Reporting] = useState([]);
  const [reporting, setReporting] = useState([]);
  // const [l2Reporting, setL2Reporting] = useState([]);
  const [loader, setLoader] = useState<any>(false);
  const [imageUrl, setImageUrl] = useState<any>('');
  const [country, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  // const [isL1Disabled, setL1Disabled] = useState<boolean>(false);
  // const [isL2Disabled, setL2Disabled] = useState<boolean>(false);
  const [branchDatas, setBranchDatas] = useState<Array<{ label: string; value: string }>>([]);
  // const [editor, setEditor] = useState<any | null>();
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    trigger,
    // setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      role: null,
      designation: '',
      email: '',
      city: '',
      state: '',
      country: '',
      phoneNumber: '',
      phoneNumberFormatted: '',
      pincode: '',
      reportingToL1: '',
      reportingToL2: '',
      saturdayleave: 'even',
      isSunday: true,
      signature: '',
      status: true,
      crm: false,
      countryCodeText: '',
      branchId: '',
      empCode: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(userFormSchema),
  });

  const getRolefn = async () => {
    const rold = await getAllListData(
      `${SETTING_ENDPOINT.roles}?roles=${String(
        Array(14)
          .fill(1)
          .map((el: number | any, id: any) => Number(el * id) + 1)
          .toString(),
      )}`,
      true,
    );
    setrollist(
      rold && rold.data && rold.data.length > 0
        ? rold.data.map((erold: any) => ({
            label: erold.name ? erold.name : '',
            value: erold.id ? erold.id : '',
          }))
        : [],
    );
  };
  const getInitialStatus = () => {
    getAllListData(`${SETTING_ENDPOINT.users}?id=${editId}`, true)
      .then((resp: any) => {
        if (resp && !_.isEmpty(resp.data)) {
          setUserData(resp.data[0]);
          const imageId = resp?.data[0]?.file ? resp?.data[0]?.file?.id : '';
          setValue('firstName', resp.data[0]?.firstName);
          setValue('lastName', resp.data[0]?.lastName);
          setValue('email', resp.data[0]?.email);
          setValue('role', resp.data[0]?.roleId);
          setValue('designation', resp.data[0]?.designation);
          setValue('city', resp.data[0]?.city);
          setValue('state', resp.data[0]?.state);
          setValue('pincode', resp.data[0]?.pincode);
          setValue('country', resp.data[0]?.country);
          setValue('crm', resp.data[0]?.crm);
          setEditorContent(resp.data[0]?.signature ? resp.data[0]?.signature : defaultText);
          setValue('reportingToL1', resp.data[0]?.reportingToL1);
          setValue('reportingToL2', resp.data[0]?.reportingToL2);
          setValue('isSunday', resp.data[0]?.isSundayLeave);
          setValue('saturdayleave', resp.data[0]?.saturdayLeave);
          setValue(
            'phoneNumberFormatted',
            `${resp.data[0]?.countryCode}${resp.data[0].phoneNumber}`,
          );
          setValue('phoneNumber', `${resp.data[0]?.countryCode}${resp.data[0].phoneNumber}`);
          setValue('countryCode', `${resp.data[0]?.countryCode}`);
          setValue('status', resp.data[0]?.status);
          setValue('branchId', resp.data[0]?.branchId);
          setValue('empCode', resp.data[0]?.empCode);
          if (!_.isEmpty(imageId)) {
            getSingleData('', `${FILE_ENDPOINT.files}/${imageId}`, true, {
              responseType: 'blob',
            }).then((fileRes: any) => {
              setUploadImage(URL.createObjectURL(fileRes));
              setImageUrl(URL.createObjectURL(fileRes));
              setLoader(false);
            });
          }
          setLoader(false);
        }
      })
      .catch((e: any) => {
        // console.log(e);
        setLoader(false);
      });
  };

  const onSubmitForm = (data: any) => {
    if (_.isEmpty(uploadImage)) {
      toast.error(String('User Image is required'), {
        autoClose: 3000,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        position: 'top-right',
        progress: undefined,
      });
      return;
    }
    const formData = new FormData();
    formData.append('firstName', data?.firstName);
    formData.append('lastName', data?.lastName);
    formData.append('email', data?.email);
    formData.append('roleId', data?.role);
    formData.append('designation', data?.designation);
    formData.append('city', data?.city);
    formData.append('state', data?.state);
    formData.append('pincode', data?.pincode);
    formData.append('country', data?.country);
    if (data?.reportingToL1 && data?.reportingToL1 !== 'none') {
      formData.append('reportingToL1', data?.reportingToL1);
    }
    if (data?.reportingToL2 && data?.reportingToL2 !== 'none') {
      formData.append('reportingToL2', data?.reportingToL2);
    }
    formData.append('status', data?.status);
    formData.append('phoneNumber', String(data?.phoneNumber).slice(data?.countryCode.length));
    formData.append(
      'countryCode',
      `+${data?.countryCode
        .split('')
        .filter((ep: string) => ep != '+')
        .join('')}`,
    );
    formData.append('organizationId', orgdy);
    formData.append('crm', data?.crm);
    formData.append('isSundayLeave', data.isSunday);
    formData.append('saturdayLeave', data.saturdayleave);
    formData.append('signature', editorContent && editorContent);
    userData?.imageId ? formData.append('imageId', userData?.imageId) : '';
    uploadImage[0] ? formData.append('profile', uploadImage[0]) : '';
    // removeImage ? formData.append('removeImageId', removeImage) : '';
    formData.append('toastType', 'user');
    formData.append('branchId', data?.branchId);
    formData.append('empCode', data?.empCode);
    if (!_.isUndefined(editId)) {
      updateData(editId, formData, `${String(SETTING_ENDPOINT.users)}`, true, {
        'Content-Type': 'multipart/form-data',
      })
        .then(() => {
          setLocalImage('');
          setUploadImage({});
          // setRemoveImage('');
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.USERS.USERSLIST}`);
          setLoader(false);
        })
        .catch((e: any) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          // console.log(e);
          setLoader(false);
        });
    } else {
      addNewData(
        formData,
        `${String(SETTING_ENDPOINT.users)}`,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then(() => {
          setLocalImage('');
          setUploadImage({});
          // setRemoveImage('');
          setLoader(false);
          resetForm();
          navigate(`${AdminPrivateRoutes.SETTINGS.USERS.USERSLIST}`);
        })
        .catch((e) => {
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          // console.log(e);
          setLoader(false);
        });
    }
  };

  const usersData = async () => {
    setLoader(true);

    getAllListData(
      `${SETTING_ENDPOINT.users}/lite?organizationId=${orgdy}&sortby=name&order=asc`,
      true,
    )
      .then((res) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: `${e.firstName}${' '}${e.lastName}`, value: e.id ? e.id : null });
        });
        setReporting(options ? options : []);
        // if (userType === 'l1Reporting') {
        //   setL1Reporting(options);
        // } else if (userType === 'l2Reporting') {
        //   setL2Reporting(options);
        // }
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
        setLoader(false);
      });
  };

  const branchData = () => {
    getAllListData(
      `${SETTING_ENDPOINT.Branch}?organizationId=${orgdy}&status=true&branchType=user&sortby=name&order=asc`,
      true,
    )
      .then((res) => {
        const options: Array<{ label: string; value: string }> = [];
        if (res.data.length) {
          res.data.forEach((e: any) => {
            options.push({ label: e.name, value: e.id });
          });
        }
        setBranchDatas(options);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getRolefn();
    branchData();
    usersData();
    if (editId) {
      getInitialStatus();
    }
  }, []);

  // useEffect(() => {
  //   const roleId = watch('role');
  //   if (roleId) {
  //     const l1RoleId = [1, 2].includes(roleId)
  //       ? 3
  //       : roleId === 3
  //         ? 4
  //         : roleId === 4
  //           ? 5
  //           : roleId === 5
  //             ? 6
  //             : roleId === 11
  //               ? 12
  //               : roleId === 12
  //                 ? 13
  //                 : roleId === 7
  //                   ? 8
  //                   : roleId === 8
  //                     ? 9
  //                     : roleId === 9
  //                       ? 10
  //                       : '';

  //     const l2RoleId = [1, 2].includes(roleId)
  //       ? 4
  //       : roleId === 3
  //         ? 5
  //         : roleId === 4
  //           ? 6
  //           : roleId === 11
  //             ? 13
  //             : roleId === 7
  //               ? 9
  //               : roleId === 8
  //                 ? 10
  //                 : '';

  //     if (roleId && ![15, 14, 13, 10, 6].includes(roleId)) {
  //       usersData('l1Reporting', l1RoleId);
  //     }

  //     if (roleId && ![15, 14, 13, 12, 10, 9, 6, 5].includes(roleId)) {
  //       usersData('l2Reporting', l2RoleId);
  //     }
  //   }
  // }, [watch('role')]);

  // useEffect(() => {
  //   const roleId = watch('role');
  //   if (roleId && [15, 14, 13, 10, 6].includes(roleId)) {
  //     setValue('reportingToL1', '');
  //     setL1Disabled(true);
  //   } else {
  //     setL1Disabled(false);
  //   }

  //   if (roleId && [15, 14, 13, 12, 10, 9, 6, 5].includes(roleId)) {
  //     setL2Disabled(true);
  //     setValue('reportingToL2', '');
  //   } else {
  //     setL2Disabled(false);
  //   }
  // }, [watch('role')]);

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor && editor.getData();
    setEditorContent(data);
  };

  const updatePhoto = (e: any) => {
    const imageFormatCheck = e?.target?.files[0]?.name.split('.');
    const formatCheck = ['tiff', 'jpeg', 'jpg', 'png'].includes(
      imageFormatCheck[imageFormatCheck.length - 1].toLowerCase(),
    );
    if (formatCheck) {
      setUploadImage(e?.target?.files);
      setLocalImage(URL.createObjectURL(e?.target?.files[0]));
    } else {
      toast.error('Upload valid images only PNG, JPEG, JPG and TIFF are allowed');
    }
  };

  useEffect(() => {
    const options: any = [];
    Country.getAllCountries().map((country) => {
      options.push({ label: country.name, value: country.isoCode });
    });
    setCountry(options);
  }, []);

  useEffect(() => {
    if (watch('country')) {
      const options: any = [];
      State.getStatesOfCountry(watch('country')).map((state: any) => {
        options.push({ label: state.name, value: state.isoCode });
      });
      setStates(options);
    }
  }, [watch('country')]);

  useEffect(() => {
    if (watch('state')) {
      const options: any = [];
      City.getCitiesOfState(watch('country'), watch('state')).map((city: any) => {
        options.push({ label: city.name, value: city.name });
      });
      setCities(options);
    }
  }, [watch('country'), watch('state')]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href=''>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Settings
            </Link>
            <Link
              underline='hover'
              color='inherit'
              href=''
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.USERS.USERSLIST)}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Users
            </Link>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              {editId ? 'Edit' : 'Add'} User
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>{editId ? 'Edit' : 'Add'} User</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='cancel-btn mr-16'
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.USERS.USERSLIST)}>
              {' '}
              Cancel
            </Button>
            <Button className='s-add-btn' type='submit' form={'user-add'}>
              Save
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='pad-box'>
          <Grid container columnSpacing={3.25}>
            <Grid item md={4} sm={12} xs={12}>
              <Typography component={'div'} className='pro-box'>
                {localImage ? (
                  <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                    <img className='default-pic' src={localImage} />
                  </Typography>
                ) : userData?.file?.id ? (
                  <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                    <img className='default-pic' src={imageUrl} />
                  </Typography>
                ) : userData?.firstName && userData?.lastName ? (
                  <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                    <Typography className='default-pic'>
                      {userData?.firstName?.toUpperCase().trim().split('')[0] +
                        userData?.lastName?.toUpperCase().trim().split('')[0]}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography className='ban-img' sx={{ backgroundImage: `url(${BanImg})` }}>
                    <img
                      className='default-pic'
                      src={Avatar}
                      style={{ objectFit: 'none', backgroundColor: '#FDEDE9' }}
                    />
                  </Typography>
                )}
                <Typography sx={{ textTransform: 'capitalize' }} className='pro-name'>
                  {!editId ? '' : `${userData?.firstName} ${userData?.lastName}`}
                </Typography>
                <Typography className='pro-role'>
                  {watch('role') && rollist.length
                    ? rollist.find((e: any) => e.value === Number(watch('role')))?.label
                    : 'User'}
                </Typography>
                <Typography className='d-flex-ja  mt-35'>
                  <Typography className='d-flex-ja'>
                    <Button
                      component='label'
                      // onClick={() => fileRef.current.click()}
                      className='theme-btn '>
                      Upload Photo
                      <input
                        hidden
                        type='file'
                        accept='image/*'
                        onChange={(e: any) => updatePhoto(e)}
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                      />
                    </Button>

                    {/* <Button
                      onClick={() => {
                        setRemoveImage(userData?.file?.id ? userData?.file?.id : '');
                        setLocalImage('');
                        setUploadImage({});
                      }}
                      className='theme-btn'
                      sx={{ opacity: _.isEmpty(uploadImage) ? '0.5' : '' }}
                      disabled={_.isEmpty(uploadImage) ? true : false}>
                      Remove Photo
                    </Button> */}
                  </Typography>
                </Typography>
                <Typography component={'div'} className='mt-147'>
                  <Typography className='join-text mb-16'>
                    Joined on <span>{moment(userData?.createdAt).format('DD MMMM YYYY')}</span>
                  </Typography>
                  <Typography className='status-text text-center'>
                    User status
                    <Controller
                      control={control}
                      defaultValue={null}
                      name='status'
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <Switch
                            value={value}
                            name={name}
                            // defaultChecked={true}
                            checked={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            className='custom-switch'
                          />
                        );
                      }}
                    />
                  </Typography>
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Typography component={'div'} className='form-box'>
                <form
                  className='private-form'
                  id={'user-add'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container columnSpacing={4.25}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          First Name <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='firstName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='First name'
                                error={errors.firstName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Last Name <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='lastName'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Last name'
                                error={errors.lastName?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Role <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='role'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={rollist ? rollist : [{ label: '', value: null }]}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                placeHolder='Role'
                                // isMulti={true}
                                error={errors.role?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Designation <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='designation'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              // <CustomSelect
                              //   labelKey={'label'}
                              //   valueKey={'value'}
                              //   options={designationData}
                              //   name={name}
                              //   value={value}
                              //   onChange={(e) => {
                              //     onChange(e);
                              //   }}
                              //   placeHolder='Designation'
                              //   error={errors.designation?.message}
                              // />
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Designation'
                                error={errors.designation?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container columnSpacing={4.25}>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Country <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='country'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={country}
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Country'
                                error={errors.country?.message}
                                onChange={(e) => {
                                  onChange(e);
                                  setValue('state', '');
                                  setValue('city', '');
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          State <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='state'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={states}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='State'
                                error={errors.state?.message}
                                onChange={(e) => {
                                  onChange(e);
                                  setValue('city', '');
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          City <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='city'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={cities}
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='City'
                                error={errors.city?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Pincode <span>*</span>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={null}
                          name='pincode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                className='custom-input'
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeHolder='Pincode'
                                type={'number'}
                                error={errors.pincode?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>L1 Reporting</Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='reportingToL1'
                          render={({ field: { onChange, value, name } }) => {
                            const opt = reporting.filter(
                              (e: any) => e.value !== getValues('reportingToL2'),
                            );
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                // options={
                                //   isL1Disabled
                                //     ? [...l1Reporting, { label: 'None', value: 'none' }]
                                //     : l1Reporting
                                // }
                                options={opt ? opt : reporting}
                                name={name}
                                value={value}
                                // value={isL1Disabled ? 'none' : value}
                                // disabled={isL1Disabled}
                                onChange={(e) => {
                                  onChange(e);
                                  trigger('reportingToL1');
                                }}
                                placeHolder='L1 Reporting'
                                error={errors.reportingToL1?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>L2 Reporting</Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='reportingToL2'
                          render={({ field: { onChange, value, name } }) => {
                            const opt = reporting.filter(
                              (e: any) => e.value !== getValues('reportingToL1'),
                            );
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={opt ? opt : reporting}
                                name={name}
                                value={value}
                                // options={
                                //   isL2Disabled
                                //     ? [...l2Reporting, { label: 'None', value: 'none' }]
                                //     : l2Reporting
                                // }
                                // name={name}
                                // value={isL2Disabled ? 'none' : value}
                                // disabled={isL2Disabled}
                                onChange={(e) => {
                                  onChange(e);
                                  trigger('reportingToL2');
                                }}
                                placeHolder=' L2 Reporting'
                                error={errors.reportingToL2?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Email <span>*</span>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={null}
                          name='email'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                inputProps={{
                                  startAdornment: (
                                    <InputAdornment position='start'>
                                      <img src={EmailIcon} alt='email' />
                                    </InputAdornment>
                                  ),
                                }}
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Email'
                                error={errors.email?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>

                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Phone No <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='phoneNumberFormatted'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              // <CustomInput
                              //   value={value}
                              //   name={name}
                              //   className='custom-input'
                              //   placeHolder='Phone Number'
                              //   error={errors.phoneNumber?.message}
                              //   onChange={(e) => {
                              //     onChange(e);
                              //   }}
                              // />
                              <CustomPhoneInput
                                placeholder=''
                                containerClass={
                                  errors.phoneNumber?.message ? 'phone-input-error' : ''
                                }
                                inputClass={
                                  errors.phoneNumber?.message
                                    ? 'phone-input phone-input-no-border'
                                    : 'phone-input'
                                }
                                initialCountry={'in'}
                                specialLabel={''}
                                searchClass={'search-custom-class'}
                                value={value}
                                enableSearch={true}
                                searchNotFound={'No Country Found'}
                                inputStyle={{
                                  width: '100%',
                                  padding: '9px 11px',
                                  borderRadius: '6px',
                                  paddingLeft: '60px',
                                  color: value && String(value).length > 0 ? '#344051' : '#bdbdbd',
                                  fontSize: '16px',
                                  fontFamily: 'Poppins-Regular',
                                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                }}
                                onChange={(value, data, event, formattedValue) => {
                                  const { countryCode, dialCode } = data;
                                  // setValue(
                                  //   'homePhoneISO2',
                                  //   countryCode.toUpperCase(),
                                  // );
                                  // console.log(countryCode,'countryCode',value,data);
                                  setValue('countryCodeText', countryCode);
                                  setValue('countryCode', dialCode);
                                  onChange(formattedValue);
                                  setValue('phoneNumber', value);
                                  setValue('phoneNumberFormatted', formattedValue);
                                  trigger('phoneNumberFormatted');
                                }}
                                error={
                                  errors.phoneNumberFormatted?.message
                                    ? errors.phoneNumberFormatted?.message
                                    : ''
                                }
                                inputProps={{ tabIndex: 11 }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Branch <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='branchId'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={
                                  branchDatas.length ? branchDatas : [{ label: '', value: null }]
                                }
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder='Select'
                                error={errors.branchId?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Employee ID <span>*</span>
                        </Typography>

                        <Controller
                          control={control}
                          defaultValue={null}
                          name='empCode'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                className='custom-input'
                                onChange={onChange}
                                value={value}
                                name={name}
                                placeHolder='Employee ID'
                                type={'text'}
                                error={errors.empCode?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>
                          Saturday Leave <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={null}
                          name='saturdayleave'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                labelKey={'label'}
                                valueKey={'value'}
                                options={[
                                  { label: 'ALL', value: 'all' },
                                  { label: 'ODD', value: 'odd' },
                                  { label: 'EVEN', value: 'even' },
                                  { label: 'NONE', value: 'none' },
                                ]}
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeHolder='Select'
                                error={errors.saturdayleave?.message}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'} style={{ marginBottom: '10px' }}>
                          Sunday Leave <span>*</span>
                        </Typography>
                        <Controller
                          control={control}
                          name='isSunday'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel value={true} control={<Radio />} label='Yes' />
                                <FormControlLabel value={false} control={<Radio />} label='No' />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        // className='custom-field'
                        // sx={{
                        //   '&>div.ck-editor': {
                        //     border:
                        //       errors.signature && errors.signature.message
                        //         ? '1.45px solid #F00'
                        //         : 'inherit',
                        //   },
                        // }}
                      >
                        <Typography component={'p'}>
                          Signature <span style={{ color: '#F00' }}>*</span>
                        </Typography>
                        {/* <Controller
                          control={control}
                          defaultValue=''
                          name='signature'
                          render={({ field: { onChange, value, name } }) => {
                            return ( */}
                        <CKEditor
                          editor={Editor}
                          data={defaultText(
                            watch('firstName'),
                            watch('lastName'),
                            rollist.find((e: any) => e.value === Number(watch('role')))?.label,
                            watch('email'),
                            watch('phoneNumberFormatted'),
                          )}
                          onChange={handleEditorChange}
                        />
                        {/* );
                          }}
                        /> */}
                        {/* {errors.signature?.message && (
                          <Typography component={'p'} className='Mui-error' sx={{color:'#F00'}}>
                            {String(errors.signature?.message)}
                          </Typography>
                        )} */}
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className=' d-flex custom-field'>
                        <Controller
                          control={control}
                          defaultValue=''
                          name='crm'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <Checkbox
                                className='custom-checkbox'
                                sx={{
                                  '&:hover': { bgcolor: 'transparent' },
                                }}
                                disableRipple
                                color='default'
                                checkedIcon={<CheckedIcon />}
                                icon={<CheckIcon />}
                                checked={value}
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e.target.checked);
                                }}
                              />
                            );
                          }}
                        />
                        <Typography
                          component={'p'}
                          style={{ marginLeft: '10px', marginTop: '5px' }}>
                          Make this user a CRM{' '}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UsersAdd;
